.left-nav{
  /*z-index: -1;*/
  width: 250px;
  padding: 16px 0;
  min-height: 100vh;
  margin-right:20px;
  -webkit-box-shadow: 0px 5px 12px -5px #000000;
  box-shadow: 0px 5px 12px -5px #000000;
}

.nav-item{
  color: #4C5B66;
  height: 40px;
  line-height: 40px;
}

.nav-icon{
  margin-top: 11px;
}

.nav-link-active{
  background-color: #EAF1FF;
  border-right: 2px solid #3E79F7;
}