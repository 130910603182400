body {
  margin: 0;
  font-family: "Font Awesome 5 Free", sans-serif; font-weight: 300; content: "\f007";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container{
  width: 100%;
  margin-top: 10px;
}

.datepicker-bootstrap-styling input{
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.react-datepicker-wrapper{
  width: 100%;
}

.fullwidth div{
  width: 100%;
}