.dashboard{
    display: flex;
}

.top-nav{
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: white;
    border: 1px solid #EBEBEC;
}

.top-nav div{
    line-height: 32px;
}