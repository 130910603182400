.patients-header{
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.search-button{
  border-radius: 0px .25rem .25rem 0 !important;
}

.patients-header-icon{
  height: 30px;
  font-size: 30px;
  margin: 8px 16px;
}

.patient-name{
  margin: 0 16px;
}

.filter-field-value{
  width: 220px !important;
}