.login-wrapper{
  display: flex;
  justify-content: center;
  /*background-color: #4980FF;*/
  height: 100vh;
  background-image: url("/src/images/backgrounds/login.png");
  background-size: cover;

}

.login-vertical-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-container{
  width: 400px;
  height: 600px;
  background-color: white;
  border-radius: 5px;
}

.form-container{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.login-title{
  font-size: 28px;
  text-align: center;
}

.login-text{
  text-align: center;
}

.button{
  color: white;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 40px;
  padding: 8.5px 1.25rem;
  font-size: 14px;
  border-radius: .625rem;
  /*color: #455560;*/
  background: #3E79F7;
  border-color: #edf2f9;
  width: 200px;
}

.input{
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: tabular-nums;
  font-variant-east-asian: normal;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8.5px 11px;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #edf2f9;
  border-radius: .625rem;
  transition: all .3s;
}

.label{
  font-size: 16px;
  margin: 2px;
  line-height: 28px;
}

.input-container{
  margin: 20px 0;
}

.button-container{
  display: flex;
  justify-content: center;
}